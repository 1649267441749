const linksProducao = {
  UNIBH:
    "https://inscricao.unibh.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  USJT:
    "https://saojudas.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNA:
    "https://inscricao.una.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  SOCIESC:
    "https://inscricao.unisociesc.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  AGES:
    "https://inscricao.ages.edu.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FASEH:
    "https://inscricao.faseh.edu.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNISUL:
    "https://inscricao.unisul.edu.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNICURITIBA:
    "https://inscricao.unicuritiba.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFG:
    "https://inscricao.centrouniversitariounifg.edu.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FPB:
    "https://fpb.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFGUA:
    "https://unifg.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UAM:
    "https://anhembi.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FADERGS:
    "https://fadergs.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  IBMR:
    "https://ibmr.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  MCAMPOS:
    "https://inscricao.mcampos.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFACS:
    "https://unifacs.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIRITTER:
    "https://uniritter.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNP:
    "https://unp.animaeducacao.com.br/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>"
};
const linksHomologacao = {
  UNIBH:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  USJT:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNA:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  SOCIESC:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  AGES:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FASEH:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNISUL:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNICURITIBA:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFG:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FPB:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFGUA:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UAM:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  FADERGS:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  IBMR:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  MCAMPOS:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIFACS:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNIRITTER:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>",
  UNP:
    "https://homoaca-php.animaeducacao.com.br/branches/master/VESTIB2/inscricao/index.php/status/index/index/codigo/<concurso>/cpf/<cpf>"
};

let links =
  process.env.VUE_APP_IND_PROD == 1 ? linksProducao : linksHomologacao;
export default links;
