<template>
  <div>
    <v-app-bar
      app
      color="secondary"
      height="60"
      v-if="logoSvg != '' && logoSvgNegativo != ''"
    >
      <v-spacer />
      <v-toolbar-title>
        <img
          :src="logoSvg"
          :alt="ie"
          :class="ie.toLowerCase() + '-logo'"
          v-if="!$vuetify.theme.dark"
        />
        <img
          :src="logoSvgNegativo"
          :alt="ie"
          :class="ie.toLowerCase() + '-logo'"
          v-else
        />
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-container id="nf-404">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-img :src="illustration" class="s-ma-n5 img-404"></v-img>
        </v-col>
        <v-col cols="12" md="6" class="text-404 s-pa-n3">
          <div class="s-ml-n3">
            <h1 class="s-pb-n3">Oooops...</h1>
            <p class="s-pb-n3">
              Parece que a página que você queria acessar desapareceu.
            </p>
            <p class="s-pb-n3" v-if="link.length > 0">
              Tente novamente a partir do link enviado para seu email ou use o
              botão abaixo para voltar para o Link de acompanhamento.
            </p>
            <p v-else>
              Tente novamente a partir do link enviado para seu email.
            </p>
            <button class="btn-a btn-a-pri" v-if="link.length > 0">
              <a :href="link" class="white--text">Me leve para o Link</a>
            </button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import urlLinks from "@/core/util/urlAcompanhamento.js";

export default {
  name: "NotFound",
  data: () => ({
    illustration: require(`@/assets/svg/Ilustracoes/illustration-404.svg`),
    logoSvg: "",
    logoSvgNegativo: "",
    link: ""
  }),
  computed: {
    ie() {
      return this.$store.state.util.ie;
    }
  },
  created() {
    this.setLink();
    this.setLogo();
  },
  methods: {
    setLogo() {
      try {
        this.logoSvg = require(`@/assets/svg/Marcas/${this.ie.toLowerCase()}.svg`);
        this.logoSvgNegativo = require(`@/assets/svg/Marcas/${this.ie.toLowerCase()}-branco.svg`);
      } catch (e) {
        this.logoSvg = "";
        this.logoSvgNegativo = "";
      }
    },
    setLink() {
      try {
        if (this.ie.length > 1) {
          this.link = urlLinks[this.ie];
          let decode = atob(this.$route.params.cpf);
          decode = decode.split(";");
          const conc = decode[0];
          const cpf = decode[1];
          this.link =
            conc.length === 4 ? this.link.replace("<concurso>", conc) : "";
          this.link = cpf.length === 11 ? this.link.replace("<cpf>", cpf) : "";
        }
      } catch (e) {
        this.link = "";
      }
    }
  }
};
</script>
